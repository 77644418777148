<template>
  <div class="card__flex card card-box mb-3">
    <div class="card-header pr-2">
      <div class="card-header--title">
        <small>{{ title }}</small>
      </div>
      <div class="card-header--actions">
        <slot name="header-actions"></slot>
      </div>
    </div>

    <div class="card-body p-0">
      <base-table
        ref="table"
        :provider="fetch"
        :fields="fields"
        :is-busy.sync="isFetching"
        :current-page.sync="currentPage"
        :number-of-pages="numberOfPages"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="10"
      >
        <!-- <template #cell(actions)="{ item }">
          <div class="text-center">
            <b-button size="sm" @click="edit(item)">{{
              actionButtonText
            }}</b-button>
          </div>
        </template>

        <template #cell(refresh)="{ item }">
          <div class="text-center">
            <b-button variant="danger" size="sm" @click="remove(item)"
              >ลบ</b-button
            >
          </div>
        </template> -->
      </base-table>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "../../mixins/formatDateMixin";
import { MPrefix, StudentDare } from "../../models";
import BaseTable from "./Base";

export default {
  mixins: [formatDateMixin],

  props: {
    title: String,
    fields: Array,
    params: Object,
    actionButtonText: {
      type: String,
      default: "แก้ไข",
    },
  },

  components: {
    BaseTable,
  },

  data() {
    return {
      isFetching: false,
      currentPage: parseInt(this.$route.query.page) || 1,
      numberOfPages: 1,
      sortBy: "",
      sortDesc: false,
    };
  },

  watch: {
    params: {
      deep: true,
      handler: "onParamsChanged",
    },
  },

  methods: {
    onParamsChanged() {
      this.currentPage = 1;
      this.numberOfPages = 1;

      this.refresh();
    },

    async fetch(ctx) {
      let data = [],
        additionalParams = {},
        numberOfPages = 1;

      if (this.params && this.params.mHeadquarterId) {
        this.$set(
          additionalParams,
          "mHeadquarterId",
          this.params.mHeadquarterId
        );
      }

      if (this.params && this.params.mDivisionId) {
        this.$set(additionalParams, "mDivisionId", this.params.mDivisionId);
      }

      if (this.params && this.params.mStationId) {
        this.$set(additionalParams, "mStationId", this.params.mStationId);
      }

      if (this.params && this.params.mSchoolId) {
        this.$set(additionalParams, "mSchoolId", this.params.mSchoolId);
      }

      if (this.params && this.params.keyword) {
        this.$set(additionalParams, "keyword", this.params.keyword);
      }

      if (this.sortBy) {
        this.$set(additionalParams, "order", this.sortBy);
        this.$set(additionalParams, "order_by", this.sortDesc ? "DESC" : "ASC");
      }

      this.isFetching = true;

      try {
        MPrefix.api().findAll();

        const promise = await StudentDare.api().findAll(
          {
            ...additionalParams,
            limit: ctx.perPage,
            offset: (ctx.currentPage - 1) * ctx.perPage,
          },
          { save: false }
        );

        let { data: responseData = [], number_of_pages = 1 } =
          promise.response.data;

        responseData.forEach((record) => {
          let name = `${record.first_name} ${record.last_name}`;

          const recordPrefix = MPrefix.find(record.mPrefixId);

          if (recordPrefix) {
            name = `${recordPrefix.m_prefix_name} ${name}`;
          }

          const baseRecord = {
            ...record,
            prefix: recordPrefix ? recordPrefix.m_prefix_name : "-",
            name,
            email: record.email || "-",
            phone_number: record.phone_number
              ? `${record.phone_number.substring(
                  0,
                  3
                )}-${record.phone_number.substring(
                  3,
                  6
                )}-${record.phone_number.substring(6, 10)}`
              : "-",
            updated_at: record.updatedAt
              ? this.$_formatDateMixin_formatShort(record.updatedAt)
              : "-",
            created_at: record.createdAt
              ? this.$_formatDateMixin_formatShort(record.createdAt)
              : "-",
          };

          const addedRecords = [];

          const { mapping_teaching_students = [] } = record;

          if (mapping_teaching_students.length) {
            mapping_teaching_students.forEach((mapping_teaching_student) => {
              let school = "-";
              let yearTerm = "-";
              let classRoom = "-";

              if (
                mapping_teaching_student.teaching_dare &&
                mapping_teaching_student.teaching_dare.m_school
              ) {
                school =
                  mapping_teaching_student.teaching_dare.m_school.m_school_name;
              }

              if (mapping_teaching_student.teaching_dare) {
                yearTerm =
                  mapping_teaching_student.teaching_dare.fiscal_year || "";

                if (mapping_teaching_student.teaching_dare.term) {
                  yearTerm += `/${mapping_teaching_student.teaching_dare.term}`;
                }

                if (mapping_teaching_student.teaching_dare.m_class) {
                  classRoom =
                    mapping_teaching_student.teaching_dare.m_class
                      .m_class_name || "";

                  const roomNumber = parseInt(
                    mapping_teaching_student.teaching_dare.room
                  );

                  if (!isNaN(roomNumber)) {
                    classRoom += `/${roomNumber}`;
                  }
                }
              }

              addedRecords.push({
                ...baseRecord,
                mapping_teaching_student,
                school,
                classRoom,
                yearTerm,
              });
            });

            data = [...data, ...addedRecords];
          } else {
            let studentYearTerm = "-";

            if (record.year) {
              studentYearTerm = `${record.year}`;

              if (record.term) {
                studentYearTerm += `/${record.term}`;
              }
            }

            data.push({
              ...baseRecord,
              school: record.m_school_name,
              classRoom: "-",
              yearTerm: studentYearTerm,
            });
          }
        });

        numberOfPages = number_of_pages;
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถดึงข้อมูลนักเรียนได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isFetching = false;
      }

      this.numberOfPages = numberOfPages;

      return data;
    },

    edit(item) {
      this.$emit("edit", item);
    },

    remove(item) {
      this.$emit("delete", item);
    },

    refresh() {
      this.$refs.table.refresh();
    },
  },
};
</script>
